// Theme colors
$primary:   #044b90;
$secondary: #A7B3BC;
$info:      #007bff;
$success:   #5cb85c;
$warning:   #edb82b;
$danger:    #d9534f;
$light:     #f5f5f5;

@import "bootstrap/scss/bootstrap.scss";

@media print {
  a[href]:after {
    content: "";
  }
}

@each $color, $value in $theme-colors {
  .bg-light-#{$color} {
    background-color: lighten($value, 40%);
  }
}

.bg-light-primary { background-color: $white !important; }
.btn-warning { color: $white; }
.btn-warning:hover { color: $white; }
.btn-warning:active { color: $white; }
.btn-warning:visited { color: $white; }
.badge-warning { color: $white; }

.small {
  font-size: 0.8em;
}

.highlight-row-english:hover {
  color: $primary;
}

.highlight-row-spanish:hover {
  color: $success;
}

.request_status {
  float: right;
  font-size: 100%;
}

.bg-gray-1 { background-color: gray("100"); }
.bg-gray-2 { background-color: gray("200"); }
.bg-gray-3 { background-color: gray("300"); }
.bg-gray-4 { background-color: gray("400"); }
.bg-gray-5 { background-color: gray("500"); }
.bg-gray-6 { background-color: gray("600"); }
.bg-gray-7 { background-color: gray("700"); }
.bg-gray-8 { background-color: gray("800"); }
.bg-gray-9 { background-color: gray("900"); }
.bg-black  { background-color: #000000; }
